<template>
  <v-card height="100%" style="display: flex; flex-direction: column">
    <v-img
      contain
      class="eur-card-img"
      v-if="promotionObj && promotionObj.banner"
      height="200"
      max-height="200"
      :src="promotionObj.banner.href"
    >
    </v-img>

    <v-card-title class="eur-card-title" style="justify-content: center">
      {{ promotionObj.name }}
    </v-card-title>

    <v-card-subtitle class="eur-card-subtitle">
      <v-tooltip bottom v-if="promotionObj.download">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon class="float-right" :href="promotionObj.download.href" target="_blank" rel="noreferrer"
            ><v-icon v-bind="attrs" v-on="on">mdi-paperclip</v-icon></v-btn
          >
        </template>
        <span>{{ $i18n.translate("Rebate Details") }}</span>
      </v-tooltip>
    </v-card-subtitle>

    <v-card-text class="ma-0 pa-0 grow">
      <p class="pa-4 ma-0" v-html="promotion.description"></p>
    </v-card-text>

    <v-divider v-if="showClaimButton"></v-divider>
    <v-card-actions>
      <v-btn
        block
        :to="getPromotionLink(promotionObj)"
        v-if="showClaimButton && promotionObj.promotionType.claimable == true"
        color="primary"
        class="mt-5 mb-5 text-center"
      >
        {{ $i18n.translate("Register") }}
        <GoogleReCaptcha :showLegend="false"></GoogleReCaptcha>
      </v-btn>
      <v-btn
        block
        :href="resellerHref"
        target="_blank"
        rel="noreferrer"
        v-if="showClaimButton && promotionObj.promotionType.claimable == false"
        color="primary"
        class="mt-5 mb-5 text-center"
      >
        {{ $i18n.translate("Find a Reseller") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import GoogleReCaptcha from "../../gapp-components/components/display/GoogleReCaptcha.vue";
import { mapGetters } from "vuex";
import moment from "moment-timezone";

export default {
  components: { GoogleReCaptcha },
  name: "EurCardSupport",
  props: {
    promotion: Object,
    showClaimButton: {
      type: Boolean,
      default: true
    },
    showRebateColumn: {
      type: Boolean,
      default: true
    },
    showRebateDetails: {
      type: Boolean,
      default: true
    },
    showSubmissionDeadline: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      env: null,
      panels: [],
      loading: false,
      promotionObj: {},
      resellerHref: "https://pro.sony/ue_US/where-to-buy/products/",
      expandedProducts: []
    };
  },
  methods: {
    getProducts(promotion) {
      if (promotion.id in this.expandedProducts) {
        return;
      }
      this.loading = true;
      return this.$api
        .get("/api/promotions/" + promotion.id + "/public")
        .then(({ data }) => {
          this.expandedProducts[promotion.id] = data.promotionProducts;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateResellerHref() {
      let selectedLanguage = this.selectedLocale.languageType.name;
      if (this.promotionObj && this.promotionObj.country && selectedLanguage) {
        switch (this.promotionObj.country.name) {
          case "USA":
            this.resellerHref = "https://pro.sony/ue_US/where-to-buy/products/";
            break;
          case "CAN":
            if (this.selectedLocale) {
              switch (this.selectedLocale.languageType.name) {
                case "en":
                  this.resellerHref = "https://pro.sony/en_CA/where-to-buy/products/";
                  break;
                case "fr":
                  this.resellerHref = "https://pro.sony/qf_CA/where-to-buy/products/";
                  break;
                default:
                  this.resellerHref = "https://pro.sony/en_CA/where-to-buy/products/";
                  break;
              }
            } else {
              this.resellerHref = "https://pro.sony/en_CA/where-to-buy/products/";
            }
            break;
          default:
            this.resellerHref = "https://pro.sony/ue_US/where-to-buy/products/";
            break;
        }
      }
    },
    isStillClaimable(objectWithEffectivity) {
      let now = moment();
      let active = false;
      if (objectWithEffectivity.effectiveDate && objectWithEffectivity.submissionDeadlineDate) {
        active = now.isBetween(
          moment(objectWithEffectivity.effectiveDate),
          moment(objectWithEffectivity.submissionDeadlineDate)
        );
      } else if (objectWithEffectivity.effectiveDate) {
        active = now.isSameOrAfter(objectWithEffectivity.effectiveDate);
      } else if (objectWithEffectivity.submissionDeadlineDate) {
        active = now.isSameOrBefore(objectWithEffectivity.submissionDeadlineDate);
      } else {
        active = true;
      }
      return active;
    },
    getPromotionLink(promotion) {
      if (promotion && promotion.name == "Product Registration") {
        return { name: "eurProductRegistration" };
      } else if (
        promotion &&
        promotion.promotionType &&
        promotion.promotionType.promotionTypeKey == this.env.VUE_APP_PROGRAM_EUR_PROMOTION_TYPE_KEY_BRAVIA
      ) {
        return { name: "eurBraviaWarranty" };
      } else if (
        promotion &&
        promotion.promotionType &&
        promotion.promotionType.promotionTypeKey == this.env.VUE_APP_PROGRAM_EUR_PROMOTION_TYPE_KEY_WHITEGLOVE
      ) {
        return { name: "eurWhiteglove" };
      } else if (
        promotion &&
        promotion.promotionType &&
        promotion.promotionType.promotionTypeKey == this.env.VUE_APP_PROGRAM_EUR_PROMOTION_TYPE_KEY_EXTENDED_WARRANTY
      ) {
        return { name: "eurExtendedWarranty" };
      } else {
        return { name: "eurClaim", params: { id: promotion.id } };
      }
    }
  },
  watch: {
    promotion() {
      this.promotionObj = this.promotion;
    },
    selectedLocale: {
      immediate: true,
      deep: true,
      handler() {
        if (this.selectedLocale) {
          this.updateResellerHref();
        }
      }
    }
  },
  created() {
    this.env = process.env;
    this.promotionObj = this.promotion;
    this.updateResellerHref();
  },
  mounted() {
    this.promotionObj = this.promotion;
  },
  computed: {
    ...mapGetters(["selectedLocale", "selectedClient"]),
    headers() {
      if (
        this.expandedProducts &&
        this.promotion &&
        this.expandedProducts[this.promotion.id] &&
        this.expandedProducts[this.promotion.id].every(elem =>
          elem.promotionProductPayouts.every(subelem => subelem.payoutType.name == "PRODUCT")
        )
      ) {
        return [{ text: this.$i18n.translate("Model"), value: "product.productKey", sortable: false }];
      } else {
        if (this.showRebateColumn) {
          return [
            { text: this.$i18n.translate("Model"), value: "product.productKey", sortable: false },
            { text: this.$i18n.translate("Rebate"), value: "payout", sortable: false }
          ];
        } else {
          return [{ text: this.$i18n.translate("Model"), value: "product.productKey", sortable: false }];
        }
      }
    }
  }
};
</script>
